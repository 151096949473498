

#page-header {

  min-height: rem(334);

  padding-top: rem(55);
  padding-bottom: rem(20);

  position: relative;

  @include breakpoint(md) {
    padding-top: rem(138);
  }

  &:before {
    content: "";
    @include absolute-full;
    z-index: 1;

    background: bg-gradient(16.16deg);

    bottom: rem(118);

    @include breakpoint(sm) {
      background: bg-gradient(11.31deg);

      bottom: rem(245);
    }

    @include breakpoint(md) {
      background: bg-gradient(5.24deg);

      bottom: rem(116);
    }
  }


  &:after{
    @include line-left();
    z-index: 2;
    opacity: .45;
  }





  &__in {
    position: relative;
    z-index: 2;

    @include container(true);

    @include breakpoint(md) {
      display: flex;
      flex-wrap: wrap;
      padding-left: 0;
      padding-right: 0;
      @include container-max-widths();

      justify-content: space-between;
    }

  }

  .page-header__title {
    margin-bottom: rem(30);
    // padding: 0 padding(l);

    .headline {
      color: white;
    }

    @include breakpoint(md) {
      margin-bottom: 0;
    }
  }

  .page-header__date{
    color:$white;
    font-size: rem(17);
    display: flex;
    align-items: center;
    margin-top: rem(15);
    .icon{
      font-size: rem(24);
      color: $white;
      opacity: .6;
      margin-right: rem(10);
    }
  }

  .page-header__undertitle{
    margin-top: rem(25);
  }
  
  .page-header__txt, .page-header__share, .page-header__img {

    @include breakpoint(md) {
      padding-left: 0;
      padding-right: 0;
      max-width: none;
    }
  }





  //------- top text
  .page-header__breadcrumb {

    @include breakpoint(sm) {
      margin-bottom: rem(40);
    }
  }

  .page-header__txt {

    @include breakpoint(sm) {
      @include container();
      @include container-max-widths();
    }

    &__in {

      @include breakpoint(sm) {
        @include offset(1);
        @include offset(1, 12, 'right');
        padding:0 padding(l);
      }
      @include breakpoint(md) {
        @include offset(0);
        @include offset(1, 12, 'right');
        padding:0 padding(l);


        display: flex;
        flex-direction: column;

        justify-content: space-between;
        padding-bottom: rem(150);
        padding-top: rem(15);
        height:100%;
      }
    }

    @include breakpoint(md) {
      @include col(5, 12, false);

      @include offset(1);
      @include offset(0, 12, 'right');

      /*display: flex;
      flex-direction: column;

      justify-content: space-between;
      padding-bottom: rem(150);
      padding-top: rem(15);

      @include offset(0);
      @include offset(0, 12, 'right');*/


    }
  }
  //-------


  //------- share
  .page-header__share {


    @include breakpoint(sm) {
      @include container();
      @include container-max-widths();
    }



    &__spacer {
      flex: 1;
      height: rem(1);
      background-color: $gray-02;
      margin-right: rem(24);
    }

    &__in {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: $gray-01;
    //  height: 100%;

      @include breakpoint(sm) {
        @include offset(1);
        @include offset(1, 12, 'right');
        padding:0 padding(l);
      }

      @include breakpoint(md) {
        @include offset(0);
        @include offset(1, 12, 'right');


      }
    }

    @include breakpoint(md) {
      @include col(5, 12, false);
      margin-top: rem(-85);

      @include offset(0, 12, 'right');
      @include offset(1);

      padding-right:padding(l);


    }
  }

  .page-header__share__list {
    display: flex;

    .page-header__share__link {

      width: rem(48);
      height: rem(48);
      border: rem(1) solid $gray-03;
      color: $gray-01;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      margin-right: rem(10);

      @include transition-default;

      .icon{
        font-size: rem(18);
      }

      &:hover {
        color: $secondary-color;
        border-color: $secondary-color;
      }

    }
  }

  //----------

  //------- image


  //-------


  .page-header__img {
    margin-bottom: rem(30);
    //padding: 0 padding(l);

    @include breakpoint(sm) {
      @include container();
      @include container-max-widths();
    }


    img {
      width: 100%;
      box-shadow: $box-shadow;
      object-fit: cover;
      max-height: rem(195);
      @include breakpoint(sm) {
        max-height: rem(420);
      }

      @include breakpoint(md) {
        min-height: rem(400);
        //height:rem(460);
        max-height: rem(500);

      }

    }

    @include breakpoint(md) {
      @include col(6, 12, false);

      margin-bottom: rem(0);

    }

    &__in {
      @include breakpoint(sm) {
        @include offset(1);
        padding:0 padding(l);
        margin-right: rem(-120);

      }

      @include breakpoint(md) {
        @include offset(0);
        padding:0 padding(l);
      }
    }

    /*&-in {
      @include breakpoint(md) {
        margin-right: rem(-120);
      }
    }*/
  }

  .round-picto{
    position: absolute;
    bottom: 0;
    left: 50%;
    z-index: 1;
    transform: translate(-50%,50%);
  }
}

//------------------------------------------------------------------------------------------------------------
//without img
//------------------------------------------------------------------------------------------------------------
#page-header.without-img {
  min-height: rem(220);
  overflow: visible;
  margin-bottom: 0;
  @include breakpoint(sm) {
    min-height: rem(295);
    padding-top: rem(35);
  }
  @include breakpoint(md){
    min-height: auto;
    padding-top: rem(130);
    max-height: rem(476);
  }

  @include breakpoint(lg){
    padding-top: rem(135);
    //max-height: rem(476);
  }
  &:before {
    bottom: rem(0);
  }
  #page-header__in{
    padding-left: 0;
  }

  .page-header__txt{
    text-align: center;
    @include breakpoint(md){
      @include offset(1,12);
      @include col(11,12,false);
      padding-bottom: rem(100);

    }
  }

  .page-header__breadcrumb{
    @include breakpoint(sm){
      margin-bottom: rem(55);
    }
    @include breakpoint(md){
      margin-bottom: rem(100);
      width: 40%;
    }
  }

  .page-header__title{
    @include breakpoint(md){
      text-align: center;
    }
  }
}