@import '~dauphine/mixins';
@import '../../core/helpers/variables';
@import '../../core/mixins/mixins';

@import '../../components/header/page-header';


.w-form {
	padding: rem(60) 0 paddingFlex(s);
	position: relative;
	@include breakpoint(sm) {
		padding: rem(70) 0 paddingFlex(m);
	}
	@include breakpoint(lg) {
		padding: paddingFlex(l) 0;
	}

	&:before {
		@include line-left();
	}

	@import './_gf-form';

	.gf_progressbar_wrapper {
		display: none;
	}

	.form-content {
		position: relative;
		@include breakpoint(sm) {
			@include offset(1);
			@include col(10, 12, false);
		}

		@include breakpoint(lg) {
			@include offset(3);
			@include col(6, 12, false);
		}

		.wysiwyg {
			@include breakpoint(lg) {
				padding-bottom: rem(10);
			}
		}

		.explanation {
			padding-bottom: paddingFlex(s);
			@include breakpoint(sm) {
				padding-bottom: paddingFlex(m);
			}
			@include breakpoint(lg) {
				padding-bottom: paddingFlex(l);
			}
		}


		.find-it {
			font-size: rem(14);
			color: #555469;

			.meter-info {
				display: flex;
				align-items: center;
				margin-top: rem(-20);
				cursor: pointer;
				@include transition-default;
				@include breakpoint(md) {
					&:hover {
						color: $secondary-color;
					}
				}

				.info {
					font-size: rem(14);
					background-color: $secondary-color;
					color: $white;
					width: rem(20);
					height: rem(20);
					display: flex;
					align-items: center;
					justify-content: center;
					border-radius: rem(20);
					margin-right: rem(5);
					padding-top: 0;
				}
			}
		}

	}

}
