@import "../fonts/font-hind-vadodara";

$icomoon-font-path: "../../static/fonts/icomoon/fonts" !default;
;

@import "../../../../static/fonts/icomoon/style";

/* ===================
	    Colors
/* ===================*/

@function primary-color($opacity : 1) {
	@return rgba(28, 26, 54, $opacity);
}

@function white($opacity : 1) {
	@return rgba(255, 255, 255, $opacity);
}

@function gray-03($opacity : 1) {
	@return rgba(199, 203, 207, $opacity);
}


$primary-color: primary-color();
$primary-color-dark: $primary-color;
$secondary-color: #3BC6E5;
$tertiary-color: #54388C;
$text-color: #000;
$success-color: #93CB1C;
$warning-color: #F4841C;

$gaz-green: #41937F;
$gaz-green-light: #4BA58F;


$gaz-yellow: #B99000;
$gaz-yellow-light: #E9B;


$gaz-orange: #BB5F09;
$gaz-orange-light: #F4841C;

$gaz-red: #A90434;
$gaz-red-light: #D11149;

$white: #fff;
$gray-01: #616B75;
$gray-02: #A6B0B9;
$gray-03: #C7CBCF;
$gray-04: #EBEBEB;
$danger: #D11149;

$overlay-color: #bbb;
$line-color: #bbb;
$border-white: rgba(255, 255, 255, 0.35);

$gradient-primary: linear-gradient(40deg, #54388B 0%, #554894 32%, #5773AC 60%, #5AB7D3 94%, #58C0D8 100%);
$gradient-primary-h: linear-gradient(224.42deg, #54388B 0%, #554894 32%, #5773AC 60%, #5AB7D3 94%, #58C0D8 100%);


$box-shadow: 2px 12px 30px -5px rgba(28, 26, 54, 0.25);
$box-shadow-gallery: 0px 50px 28px -20px rgba(0, 0, 0, 0.25);
$box-shadow-gaz-network: 2px 12px 12px -5px rgba(28, 26, 54, 0.25);


$side-shadow: rem(-40) rem(-40) rem(100) rem(-80) #0A2B38, rem(40) rem(-40) rem(100) rem(-80) #0A2B38;

/* ===================
	    Fonts
/* ===================*/
$font-primary: 'Hind Vadodara', sans-serif;
$font-secondary: 'Hind Vadodara', sans-serif;
$font-icon: 'icomoon';

/* ===================
    Config
/* ===================*/
$debug-rem: true;

/* ===================
    Dauphine var default
/* ===================*/
$columns: true;
$sizing: true;

$grid: false;
$order: false;
$direction: false;
$display: false;
$spacing: false;

/* ===================
    Margin & padding
/* ===================*/
$paddings: (
		s : rem(4),
		m : rem(8),
		l : rem(12)
);

@function padding($varname) {
	@return map-get($paddings, $varname);
}


/* ===================
    FlexContent
/* ===================*/
$paddingFlex: (
		s : rem(40),
		m : rem(60),
		l : rem(80)
);

@function paddingFlex($varname) {
	@return map-get($paddingFlex, $varname);
}

;
/* ===================
    Animation
/* ===================*/

$animTranslation: rem(60);
$translateTop: translate3d(0, $animTranslation, 0);
$animCurbe: cubic-bezier(0, 0, 0.58, 1);
$animTime: 400ms;

$anim-cubic-2: cubic-bezier(0.16, 0.84, 0.44, 1);
$anim-curbic-time-2: 400ms;

//Sorry for that
.container {
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	padding-left: 12px;
	padding-right: 12px;
}
