@import "variables";

@font-face {
	font-family: '#{$icomoon-font-family}';
	src: url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?a2gchp');
	src: url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?a2gchp#iefix') format('embedded-opentype'),
	url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?a2gchp') format('truetype'),
	url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?a2gchp') format('woff'),
	url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?a2gchp##{$icomoon-font-family}') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: block;
}

i:not(.mce-ico) {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: '#{$icomoon-font-family}' !important;
	speak: never;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-espace-client {
	&:before {
		content: $icon-espace-client;
	}
}

.icon-compteur {
	&:before {
		content: $icon-compteur;
	}
}

.icon-alert {
	&:before {
		content: $icon-alert;
	}
}

.icon-chart {
	&:before {
		content: $icon-chart;
	}
}

.cooking {
	&:before {
		content: $icon-cooking;
	}
}

.window {
	&:before {
		content: $icon-window;
	}
}

.fire {
	&:before {
		content: $icon-fire;
	}
}

.termostat {
	&:before {
		content: $icon-termostat;
	}
}

.shower {
	&:before {
		content: $icon-shower;
	}
}

.water-sink {
	&:before {
		content: $icon-water-sink;
	}
}

.worker {
	&:before {
		content: $icon-worker;
	}
}

.heating-water {
	&:before {
		content: $icon-heating-water;
	}
}

.air {
	&:before {
		content: $icon-air;
	}
}

.temperature-list {
	&:before {
		content: $icon-temperature-list;
	}
}

.blinds-raised {
	&:before {
		content: $icon-blinds-raised;
	}
}

.window-frame {
	&:before {
		content: $icon-window-frame;
	}
}

.icon-worker-2 {
	&:before {
		content: $icon-worker-2;
	}
}

.icon-graduation {
	&:before {
		content: $icon-graduation;
	}
}

.icon-handshake {
	&:before {
		content: $icon-handshake;
	}
}

.icon-newspaper {
	&:before {
		content: $icon-newspaper;
	}
}

.icon-hydrogen {
	&:before {
		content: $icon-hydrogen;
	}
}

.icon-recycling {
	&:before {
		content: $icon-recycling;
	}
}

.icon-gas-station-full {
	&:before {
		content: $icon-gas-station-full;
	}
}

.icon-big-arrowup {
	&:before {
		content: $icon-big-arrowup;
	}
}

.icon-thumbup {
	&:before {
		content: $icon-thumbup;
	}
}

.icon-big-moins {
	&:before {
		content: $icon-big-moins;
	}
}

.icon-big-plus {
	&:before {
		content: $icon-big-plus;
	}
}

.icon-camera {
	&:before {
		content: $icon-camera;
	}
}

.icon-big-euro {
	&:before {
		content: $icon-big-euro;
	}
}

.icon-heart {
	&:before {
		content: $icon-heart;
	}
}

.icon-param {
	&:before {
		content: $icon-param;
	}
}

.icon-pause {
	&:before {
		content: $icon-pause;
	}
}

.icon-lightbulb1 {
	&:before {
		content: $icon-lightbulb1;
	}
}

.icon-tools {
	&:before {
		content: $icon-tools;
	}
}

.icon-assignment {
	&:before {
		content: $icon-assignment;
	}
}

.icon-quote1 {
	&:before {
		content: $icon-quote1;
	}
}

.icon-document {
	&:before {
		content: $icon-document;
	}
}

.icon-pdf {
	&:before {
		content: $icon-pdf;
	}
}

.icon-photo {
	&:before {
		content: $icon-photo;
	}
}

.icon-callback {
	&:before {
		content: $icon-callback;
	}
}

.icon-phonecall {
	&:before {
		content: $icon-phonecall;
	}
}

.icon-quote {
	&:before {
		content: $icon-quote;
	}
}

.icon-gnv {
	&:before {
		content: $icon-gnv;
	}
}

.icon-add {
	&:before {
		content: $icon-add;
	}
}

.icon-addlocation {
	&:before {
		content: $icon-addlocation;
	}
}

.icon-arrowback {
	&:before {
		content: $icon-arrowback;
	}
}

.icon-arrowbotom {
	&:before {
		content: $icon-arrowbotom;
	}
}

.icon-arrowright {
	&:before {
		content: $icon-arrowright;
	}
}

.icon-arrowup {
	&:before {
		content: $icon-arrowup;
	}
}

.icon-avatar {
	&:before {
		content: $icon-avatar;
	}
}

.icon-bullet {
	&:before {
		content: $icon-bullet;
	}
}

.icon-cards {
	&:before {
		content: $icon-cards;
	}
}

.icon-chat {
	&:before {
		content: $icon-chat;
	}
}

.icon-check {
	&:before {
		content: $icon-check;
	}
}

.icon-check-filled {
	&:before {
		content: $icon-check-filled;
	}
}

.icon-close {
	&:before {
		content: $icon-close;
	}
}

.icon-comments {
	&:before {
		content: $icon-comments;
	}
}

.icon-creditcard {
	&:before {
		content: $icon-creditcard;
	}
}

.icon-date {
	&:before {
		content: $icon-date;
	}
}

.icon-date-1 {
	&:before {
		content: $icon-date-1;
	}
}

.icon-delete {
	&:before {
		content: $icon-delete;
	}
}

.icon-doc {
	&:before {
		content: $icon-doc;
	}
}

.icon-download {
	&:before {
		content: $icon-download;
	}
}

.icon-edit {
	&:before {
		content: $icon-edit;
	}
}

.icon-electric {
	&:before {
		content: $icon-electric;
	}
}

.icon-electricmeter {
	&:before {
		content: $icon-electricmeter;
	}
}

.icon-euros {
	&:before {
		content: $icon-euros;
	}
}

.icon-eye {
	&:before {
		content: $icon-eye;
	}
}

.icon-eye-blind {
	&:before {
		content: $icon-eye-blind;
	}
}

.icon-facebook {
	&:before {
		content: $icon-facebook;
	}
}

.icon-filter {
	&:before {
		content: $icon-filter;
	}
}

.icon-home {
	&:before {
		content: $icon-home;
	}
}

.icon-info {
	&:before {
		content: $icon-info;
	}
}

.icon-instagram {
	&:before {
		content: $icon-instagram;
	}
}

.icon-leaf {
	&:before {
		content: $icon-leaf;
	}
}

.icon-lightbulb {
	&:before {
		content: $icon-lightbulb;
	}
}

.icon-link {
	&:before {
		content: $icon-link;
	}
}

.icon-linkedin {
	&:before {
		content: $icon-linkedin;
	}
}

.icon-list {
	&:before {
		content: $icon-list;
	}
}

.icon-mail {
	&:before {
		content: $icon-mail;
	}
}

.icon-menu {
	&:before {
		content: $icon-menu;
	}
}

.icon-miniarrowbottom {
	&:before {
		content: $icon-miniarrowbottom;
	}
}

.icon-miniarrowleft {
	&:before {
		content: $icon-miniarrowleft;
	}
}

.icon-miniarrowright {
	&:before {
		content: $icon-miniarrowright;
	}
}

.icon-miniarrowup {
	&:before {
		content: $icon-miniarrowup;
	}
}

.icon-minicards {
	&:before {
		content: $icon-minicards;
	}
}

.icon-minimize {
	&:before {
		content: $icon-minimize;
	}
}

.icon-more {
	&:before {
		content: $icon-more;
	}
}

.icon-mylocation {
	&:before {
		content: $icon-mylocation;
	}
}

.icon-navigation {
	&:before {
		content: $icon-navigation;
	}
}

.icon-partners {
	&:before {
		content: $icon-partners;
	}
}

.icon-phone {
	&:before {
		content: $icon-phone;
	}
}

.icon-pinterest {
	&:before {
		content: $icon-pinterest;
	}
}

.icon-place {
	&:before {
		content: $icon-place;
	}
}

.icon-play {
	&:before {
		content: $icon-play;
	}
}

.icon-plug {
	&:before {
		content: $icon-plug;
	}
}

.icon-question {
	&:before {
		content: $icon-question;
	}
}

.icon-reload {
	&:before {
		content: $icon-reload;
	}
}

.icon-schedule {
	&:before {
		content: $icon-schedule;
	}
}

.icon-search {
	&:before {
		content: $icon-search;
	}
}

.icon-searchfournisseur {
	&:before {
		content: $icon-searchfournisseur;
	}
}

.icon-searchinfo {
	&:before {
		content: $icon-searchinfo;
	}
}

.icon-searchinstal {
	&:before {
		content: $icon-searchinstal;
	}
}

.icon-services {
	&:before {
		content: $icon-services;
	}
}

.icon-share {
	&:before {
		content: $icon-share;
	}
}

.icon-touch {
	&:before {
		content: $icon-touch;
	}
}

.icon-twitter {
	&:before {
		content: $icon-twitter;
	}
}

.icon-warning {
	&:before {
		content: $icon-warning;
	}
}

.icon-work {
	&:before {
		content: $icon-work;
	}
}

.icon-cycle {
	&:before {
		content: $icon-cycle;
	}
}

.icon-worker {
	&:before {
		content: $icon-worker;
	}
}

.icon-building {
	&:before {
		content: $icon-building;
	}
}

.icon-equality {
	&:before {
		content: $icon-equality;
	}
}

.icon-feuille {
	&:before {
		content: $icon-feuille;
	}
}

.icon-hard-hat {
	&:before {
		content: $icon-hard-hat;
	}
}
