.gform_page_footer {
	display: flex;
	justify-content: space-between;
	align-items: center;

	.gform_previous_button {
		padding: 0;

		&:hover {
			.pipe__buttons__back__txt {
				color: $secondary-color;
			}
		}

		.icon {
			color: $secondary-color;
			font-size: 22px;
			line-height: 22px;
			position: absolute;
			left: 0;
			top: 8px;
		}

		.pipe__buttons__back__txt {
			padding-left: rem(28);
			@include transition-default;
		}
	}

	.gform_ajax_spinner {
		display: none;
	}
}

.form-rgpd {
	padding-top: rem(40);
	color: $gray-01;
	font-size: rem(15);
	line-height: normal;
	@include breakpoint(lg) {
		padding-top: rem(60);
	}
}

.info {
	color: $gray-01;
	font-size: rem(15);
	padding-top: rem(60);
}


.gfield {
	margin-bottom: rem(30);
}

.gform_footer {
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.form-confirmation-show {
	text-align: center;

	.btn {
		margin-top: rem(40);
		text-align: center;
	}
}