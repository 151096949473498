/* ===================
	    Fonts
/* ===================*/
%font-primary-regular{ font-family:$font-primary; font-weight:400; }
%font-primary-light{ font-family:$font-primary; font-weight:300; }

%font-secondary-regular{ font-family:$font-secondary; font-weight:400; }
%font-secondary-bold{ font-family:$font-secondary; font-weight:700; }


/* ===================
	    Icomoon
/* ===================*/

@mixin icon($content, $color: $primary-color, $size: rem(24)) {
    content: $content;
    font-family: "icomoon";
    font-size: $size;
    color: $color;
    font-weight: 400;
}


/* ===================
	    Background
/* ===================*/
@mixin gradient-pseudo($bg : $gradient-primary ){
    content :"";
    width: 100%;
    height: 100%;
    background:$bg;
    position: absolute;
    left: 0;
    top: 0;
    @include transition-default;
}

@mixin front(){
    position: relative;
    z-index: 1;
}

@mixin line-left(){
    content:"";
    top:rem(0);
    bottom: rem(0);
    left: calc(((100% - #{rem(720)}) / 2) + #{rem(36)});
    width: rem(1);
    background-color:$gray-03;
    position: absolute;
    display: none;
    @include breakpoint(sm){
        display: block;
    }
    @include breakpoint(md){
        left: calc(((100% - #{rem(940)}) / 2) + #{rem(24)});
    }
    @include breakpoint(lg){
        left: calc(((100% - #{rem(1200)}) / 2) + #{rem(24)});
    }
    @include breakpoint(xlg){
        left: calc(((100% - #{rem(1400)}) / 2) + #{rem(24)});
    }
}

@function bg-gradient($angle) {
    @return linear-gradient($angle, #50BDE0 0.6%, #33186A 91.31%);
}

@mixin text-gradient() {
    background:-webkit-gradient(linear, right bottom, right top , from($secondary-color), to($tertiary-color));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color:$secondary-color;
}


/* ===================
	    Layout
/* ===================*/
@mixin paddingSection(){
    padding: paddingFlex(s) 0;

    @include breakpoint(sm) {
        padding: paddingFlex(m) 0;
    }

    @include breakpoint(lg) {
        padding: paddingFlex(l) 0;
    }
}

@mixin paddingBottom() {
    padding-bottom: paddingFlex(s);

    @include breakpoint(sm) {
        padding-bottom: paddingFlex(m);
    }

    @include breakpoint(lg) {
        padding-bottom: paddingFlex(l);
    }
}

@mixin paddingTop() {
    padding-top: paddingFlex(s);

    @include breakpoint(sm) {
        padding-top: paddingFlex(m);
    }

    @include breakpoint(lg) {
        padding-top: paddingFlex(l);
    }
}