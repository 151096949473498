/* gujarati */
@font-face {
  font-family: 'Hind Vadodara';
  font-style: normal;
  font-weight: 400;
  src: local('Hind Vadodara Regular'), local('HindVadodara-Regular'), url(https://fonts.gstatic.com/s/hindvadodara/v5/neINzCKvrIcn5pbuuuriV9tTQInVrEsf.woff2) format('woff2');
  unicode-range: U+0964-0965, U+0A80-0AFF, U+200C-200D, U+20B9, U+25CC, U+A830-A839;
}
/* latin-ext */
@font-face {
  font-family: 'Hind Vadodara';
  font-style: normal;
  font-weight: 400;
  src: local('Hind Vadodara Regular'), local('HindVadodara-Regular'), url(https://fonts.gstatic.com/s/hindvadodara/v5/neINzCKvrIcn5pbuuuriV9tTQJzVrEsf.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Hind Vadodara';
  font-style: normal;
  font-weight: 400;
  src: local('Hind Vadodara Regular'), local('HindVadodara-Regular'), url(https://fonts.gstatic.com/s/hindvadodara/v5/neINzCKvrIcn5pbuuuriV9tTQJLVrA.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* gujarati */
@font-face {
  font-family: 'Hind Vadodara';
  font-style: normal;
  font-weight: 600;
  src: local('Hind Vadodara SemiBold'), local('HindVadodara-SemiBold'), url(https://fonts.gstatic.com/s/hindvadodara/v5/neIQzCKvrIcn5pbuuuriV9tTSE3xuW8-oBOL.woff2) format('woff2');
  unicode-range: U+0964-0965, U+0A80-0AFF, U+200C-200D, U+20B9, U+25CC, U+A830-A839;
}
/* latin-ext */
@font-face {
  font-family: 'Hind Vadodara';
  font-style: normal;
  font-weight: 600;
  src: local('Hind Vadodara SemiBold'), local('HindVadodara-SemiBold'), url(https://fonts.gstatic.com/s/hindvadodara/v5/neIQzCKvrIcn5pbuuuriV9tTSE3xuXo-oBOL.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Hind Vadodara';
  font-style: normal;
  font-weight: 600;
  src: local('Hind Vadodara SemiBold'), local('HindVadodara-SemiBold'), url(https://fonts.gstatic.com/s/hindvadodara/v5/neIQzCKvrIcn5pbuuuriV9tTSE3xuXQ-oA.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* gujarati */
@font-face {
  font-family: 'Hind Vadodara';
  font-style: normal;
  font-weight: 700;
  src: local('Hind Vadodara Bold'), local('HindVadodara-Bold'), url(https://fonts.gstatic.com/s/hindvadodara/v5/neIQzCKvrIcn5pbuuuriV9tTSCnwuW8-oBOL.woff2) format('woff2');
  unicode-range: U+0964-0965, U+0A80-0AFF, U+200C-200D, U+20B9, U+25CC, U+A830-A839;
}
/* latin-ext */
@font-face {
  font-family: 'Hind Vadodara';
  font-style: normal;
  font-weight: 700;
  src: local('Hind Vadodara Bold'), local('HindVadodara-Bold'), url(https://fonts.gstatic.com/s/hindvadodara/v5/neIQzCKvrIcn5pbuuuriV9tTSCnwuXo-oBOL.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Hind Vadodara';
  font-style: normal;
  font-weight: 700;
  src: local('Hind Vadodara Bold'), local('HindVadodara-Bold'), url(https://fonts.gstatic.com/s/hindvadodara/v5/neIQzCKvrIcn5pbuuuriV9tTSCnwuXQ-oA.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}